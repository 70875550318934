import { render, staticRenderFns } from "./MeetingSidebar.vue?vue&type=template&id=2677d7c0&v-if=loaded&"
var script = {}


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports